import "./Body.css";
import { useState } from "react";
import {
  List,
  ListItemText,
  ListItem,
  IconButton,
  Divider,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { remove } from "../app/reducers/listSlice";

function ListContent() {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.list);
  const random = useSelector((state) => state.random);

  if (list.length == 0 || random.randomOne || random.randomMatch ) {
    return;
  }

  return (
    <List style={{marginTop: '1em'}}>
      {list.map((e, id) => {
        return (
          <>
            <ListItem
              key={id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => dispatch(remove(e))}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <ListItemText primary={e} />
            </ListItem>
            <Divider></Divider>
          </>
        );
      })}
    </List>
  );
}

export default ListContent;
