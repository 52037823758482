import "./Body.css";
import { useState } from "react";
import {
  Box,
  Button,
  Divider,
} from "@mui/material";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMatch, deleteSelect, match, select } from "../app/reducers/randomSlice";

function ActionContent() {

  const list = useSelector((state) => state.list);
  const random = useSelector((state) => state.random);
  const dispatch = useDispatch();

  if (list.length == 0) {
    return;
  }

  if (random.randomOne || random.randomMatch) {    
    return (
      <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'10px', marginTop: '2em'}}>
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          disableElevation
          disabled={list.length<2}
          style={{ fontWeight: "bold"}}
          onClick={() => random.randomOne ? dispatch(deleteSelect()) : dispatch(deleteMatch())}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disableElevation
          disabled={list.length == 0}
          style={{ fontWeight: "bold"}}
          onClick={() => random.randomOne ? dispatch(select(list)) : dispatch(match(list))}
        >
          Try again
        </Button>
      </Box>
    );
  }


  return (
    <Box style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'10px', marginTop: '2em'}}>
      <Button
        variant="outline"
        color="primary"
        size="medium"
        disableElevation
        disabled={list.length<2}
        style={{ fontWeight: "bold"}}
        onClick={()=>dispatch(match(list))}
      >
        Create match
      </Button>
      <Button
        variant="outline"
        color="primary"
        size="medium"
        disableElevation
        disabled={list.length == 0}
        style={{ fontWeight: "bold"}}
        onClick={()=>dispatch(select(list))}
      >
        Select one
      </Button>
    </Box>
  );
}

export default ActionContent;
