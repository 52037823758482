import "./Body.css";
import { useState } from "react";
import {
  List,
  ListItemText,
  ListItem,
  Divider,
  Typography,
} from "@mui/material";

import React from "react";
import { useSelector } from "react-redux";

function ShowContent() {
  const random = useSelector((state) => state.random);

  if (!random.randomOne && !random.randomMatch) {
    return;
  }

  if (random.randomMatch) {
    return (
      <List style={{ marginTop: "1em" }}>
        {random.randomMatch.map((e, id) => {
          return (
            <>
              <ListItem key={id} style={{
                // display:"flex",
                // justifyContent:"center"
                display: "grid",
                gridAutoFlow: "column",
                gridAutoColumns: "33%"
              }}>
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {e[0]}
                </Typography>
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "26px",
                    fontWeight: "bold",
                    marginLeft: "0.5em",
                    marginRight: "0.5em",
                  }}
                >
                  -
                </Typography>
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {e[1]}
                </Typography>
              </ListItem>
              <Divider></Divider>
            </>
          );
        })}
      </List>
    );
  }

  if (random.randomOne) {
    return (
      <List style={{ marginTop: "1em" }}>
        <ListItem>
          <Typography
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "bold",
              margin: "auto"
            }}
          >
            {random.randomOne}
          </Typography>
        </ListItem>
        <Divider></Divider>
      </List>
    );
  }
}

export default ShowContent;
