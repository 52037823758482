import { createSlice } from '@reduxjs/toolkit'

const initialState = []

export const listSlice = createSlice({
  name: 'list',
  initialState,
  reducers: {
    add: (state, action) => {
      state.push(action.payload)
    },
    remove: (state, action) => {
      state.splice(state.indexOf(action.payload), 1);
    },
    update: (state, action) => {
      //state[action.payload.index] = action.payload.data
    },
  },
})

// Action creators are generated for each case reducer function
export const { add, remove, update } = listSlice.actions

export default listSlice.reducer