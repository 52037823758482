import "./Body.css";
import { useState } from "react";
import {
  Card,
  Typography,
  Fade,
  CardContent,
  Container,
  Box,
  TextField,
  Button,
} from "@mui/material";

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { add } from "../app/reducers/listSlice";

function Form() {
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();

  const addItem = () => {
    dispatch(add(url));
    setUrl("");
  };

  const random = useSelector((state) => state.random);

  if (random.randomOne || random.randomMatch ) {
    return;
  }

  return (
    <Box style={{gap:'10px'}} className="row align-items-center justify-content-center">
      <TextField
        className="col-12 col-sm-7"
        label="List item"
        variant="outlined"
        value={url}
        size="small"
        color="primary"
        style={{ fontWeight: "bold" }}
        onChange={(ev) => setUrl(ev.target.value)}
      />
      <Button
        className="col-12 col-sm-4"
        variant="contained"
        color="primary"
        size="medium"
        disableElevation
        style={{ fontWeight: "bold"}}
        onClick={() => addItem()}
      >
        Add to list
      </Button>
    </Box>
  );
}

export default Form;
