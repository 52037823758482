import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  randomOne: null,
  randomMatch: null
}

export const randomSlice = createSlice({
  name: 'random',
  initialState,
  reducers: {
    deleteMatch: (state) => {
      state.randomMatch = null;
    },
    match: (state, action) => {
      const arrMatch = [];
      const copyArr = [...action.payload];
      var loop = 0;
      while (copyArr.length > 0) {
        const idx = Math.floor(Math.random() * copyArr.length);
        const randomElement = copyArr.splice(idx, 1)[0];
        if(arrMatch[loop] == null) arrMatch[loop] = [];
        if (arrMatch[loop].length < 2){
          arrMatch[loop].push(randomElement);
        }else{
          loop++;
          if(arrMatch[loop] == null) arrMatch[loop] = [];
          arrMatch[loop].push(randomElement);
        }
      }
      state.randomMatch = arrMatch;
    },
    deleteSelect: (state) => {
      state.randomOne = null;
    },
    select: (state, action) => {
      var rand = Math.floor(Math.random() * action.payload.length);
      state.randomOne = action.payload[rand];
    },
  },
})

export const { match, select, deleteSelect, deleteMatch } = randomSlice.actions

export default randomSlice.reducer